import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { IntlProvider } from 'react-intl';
import { createRoot } from 'react-dom/client';
import { AuthProvider } from 'contexts/auth';
import { EnvironmentProvider } from 'contexts/environment';
import { BreadcrumbsProvider } from '@HarrisonKeeling/design-system';
import App from './app';
import './index.css';

createRoot(document.getElementById('root')).render(
    <IntlProvider locale="en">
        <DndProvider backend={HTML5Backend}>
            <BreadcrumbsProvider>
                <EnvironmentProvider>
                    <AuthProvider>
                        <App />
                    </AuthProvider>
                </EnvironmentProvider>
            </BreadcrumbsProvider>
        </DndProvider>
    </IntlProvider>
);
