import React, { createContext, useContext, useMemo, useReducer } from 'react';
import * as client from './client';

const EnvironmentContext = createContext();

const initReducer = () => ({
    loaded: false,
});

const reducer = (state, { action, value }) => {
    switch (action) {
        case 'setConfig': {
            return { ...state, ...value };
        }
        case 'setLoaded': {
            return { ...state, loaded: true };
        }
        default:
            throw new Error(`Unexpected action type ${action}`);
    }
};

export const EnvironmentProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, props, initReducer);
    const value = useMemo(() => [state, dispatch], [state]);

    return <EnvironmentContext.Provider value={value} {...props} />;
};

export const useEnvironment = () => {
    const context = useContext(EnvironmentContext);
    if (!context) {
        throw new Error(
            'useEnvironment must be used inside an EnvironmentProvider'
        );
    }

    const [state, dispatch] = context;

    const fetchConfig = async () =>
        client
            .fetchConfig()
            .then((config) => dispatch({ action: 'setConfig', value: config }))
            .then(() => dispatch({ action: 'setLoaded', value: true }));

    return {
        state,
        dispatch,
        fetchConfig,
    };
};

export default { useEnvironment, EnvironmentProvider };
