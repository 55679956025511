import { Header } from '@HarrisonKeeling/design-system';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'contexts/auth';
import { useEnvironment } from 'contexts/environment';

const Navigation = () => {
    const {
        logout,
        state: { user },
    } = useAuth();
    const {
        state: { scheduling },
    } = useEnvironment();
    const history = useHistory();

    const onClick = (event) => {
        if (event === 'logout') return logout();
        if (event === 'schedule') return window.location.assign(scheduling);
        if (event === 'settings') return history.push('/settings');
    };

    return (
        <Header
            onClick={onClick}
            options={[
                { title: 'Manage Schedule', value: 'schedule' },
                { title: 'Settings', value: 'settings' },
                { title: 'Log Out', value: 'logout' },
            ]}
            user={user}
        />
    );
};

export default Navigation;
